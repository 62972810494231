.titleStyle {
  font-family: 'Roboto';
  font-size: 25px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: 0.31px;
  color: #333333;
  padding-bottom: 5px;
}

.textStyle {
  font-family: Roboto;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.58;
  letter-spacing: 0.15px;
  color: #333;
}

.subTitleStyle {
  font-family: Roboto;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.05;
  letter-spacing: 0.23px;
  color: black;
}

.subHeaderFontStyle {
  font-family: Roboto;
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.58;
  letter-spacing: 0.15px;
}

.downloadSection {
  background-color: #f7f7f7;
  padding: 5px 9px 4px 10px;
  margin-bottom: 14px;
}

.downloadSectionImageIcon {
  width: 12%;
  min-width: 50px;
  padding-left: 3px;
}

.downloadSectionText {
  color: black;
  width: 65%;
  min-width: 100px;
}

.downloadSectionButton {
  /* width: 40%; */
  min-width: 226px;
  padding: 3px 0px 3px 0px;
}

.downloadSectionMethod {
  width: 14%;
  min-width: 55px;
  padding-left: 3px;
}

.downloadButton {
  /* min-width: 70px; */
  width: 85%;
  min-height: 40px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.downloadButtonActive {
  background-image: linear-gradient(
      to bottom,
      rgba(45, 95, 167, 0.25),
      rgba(45, 95, 167, 0.25)
    ),
    linear-gradient(101deg, #6eb0e1 7%, #a8b3dd 104%);
}

.downloadButtonActive:hover {
  background-image: linear-gradient(
      to bottom,
      rgba(45, 95, 167, 0.6),
      rgba(45, 95, 167, 0.6)
    ),
    linear-gradient(101deg, #6eb0e1 7%, #a8b3dd 104%);
}


.instructionsCard {
  /* max-width: ; */
  width: 100%;
  min-width: 500px;
  padding: 20px;
}
