.navContainer {
  background-color: #fff !important;
  padding-left: 2.4% !important;
  padding-right: 1.8% !important;
}

.userName {
  padding: 0px 0.6vw;
  font-size: 16px;
  color: black;
}
.loader {
  width: 20px;
  height: 20px;
  border: 2px solid #6eb0e1;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
  }

  @keyframes rotation {
  0% {
      transform: rotate(0deg);
  }
  100% {
      transform: rotate(360deg);
  }
  } 