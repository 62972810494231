.userField {
  cursor: pointer;
}

.userWrap {
  max-width: 230px;
}

.userField:hover {
  color: #2d5fa7;
}
