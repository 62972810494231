.Mask {
  position: relative;
  //width: 1302px !important;
  width: 95% !important;
  max-height: 300px !important;
  margin-top: 20px !important;
  margin-left: auto !important;
  margin-right: auto !important;
  border-radius: 5px !important;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.12) !important;
  background-color: #fff !important;
}

.filterBar {
  display: flex;
  flex-wrap: wrap;
  //overflow-x: auto;
}

.separator {
  width: 1px;
  max-height: 57px;
  border: solid 1px #e3e3e3;
}

.filters {
  padding: 14px;
}

.viewName {
  // width: -webkit-fill-available;
  // min-width: 112px;
  flex-grow: 1;
}

.viewIcons {
  display: flex;
  width: 52px;
  padding: 14px;
  cursor: pointer;
}

.rightSection {
  display: flex;
}