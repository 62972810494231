@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');
 
*{
  font-family: 'Roboto',-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
}

.pageContainer {
  position: relative;
  width: 95% !important;
  margin-top: 20px !important;
  margin-left: 2.6% !important;
  margin-right: 2.6% !important;
  margin-bottom: 3%;
  overflow-x: auto;
}


// .tableHead {
//   font-size: 14px;
//   padding: 0% !important;
// }

// .tableRow {
//   font-size: 12px;
//   padding: 0% !important;
// }

// .themeBtn {
//   cursor: pointer;
// }

// .advSelect {
//   width: 200px !important;
// }

.listTable{
  width: 99.80%;
  margin-left: 0.1%;
}

.textWrap {
  width: 41vw;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.codeblock {
  display: inline-block;
  width: 67.5vw;
  min-width: 810px;
  height: 560px;
}

.tableHead {
  width: 106px;
  height: 19px;
  margin: 0 2px 0 0;
  font-size: 15px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.26;
  letter-spacing: 0.19px;
  color: #333;
}

.verifyText {
  color: #df1b1b;
}

.unverifyText {
  color: #f2d893;
}
.reportedPhishText {
  color: #7EB1E0;
}

.popover-arrow {
  position: absolute;
  top: -8px;
  right: 14px;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 8px solid #fff;
  width: 0;
  height: 0;

  -webkit-filter: drop-shadow(0 -1px 1px rgba(0, 0, 0, 0.26));
}

.scrollX {
  width: 100%;
  height: 100%;
  overflow-x: auto;
}