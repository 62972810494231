.headerBg {
    height: 55px;
    border-radius: 5px;
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.12);
    background-color: #ffffff;
}

.detailsContainer {
    border-radius: 5px;
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.12);
    background-color: #ffffff;
}

.inputStyle {
    width: 382px;
    height: 34px;
    border-radius: 5px;
    padding-left: 15px;
    border: none;
    background-color: #f7f7f7;
    font-family: 'Roboto';
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: 0.15px;
    color: var(--dark-slate-blue);
}

.inputStyle::placeholder {
    font-family: Roboto;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    color: var(--dark-slate-blue);
}

.submitButton {
    height: 40px;
    border-radius: 5px;
    background-color: #6eb0e1;
    font-family: 'Roboto';
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    color: #ffffff;
    border: none;
}

.cancelButton {
    height: 34px;
    border-radius: 5px;
    border: 2px solid #6eb0e1;
    font-family: 'Roboto';
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    color: #859dce;
}


.border {
    height: 1px;
    border-top: solid 1px #e3e3e3;
}

.title {
    font-family: 'Roboto';
    font-size: 18px;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: 0.31px;
    color: #333333;
  }

  .shortInfoStyle {
    /* width: 1302px; */
    height: 131px;
    border-radius: 5px;
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.12);
    background-color: #ffffff;
}

.addButton {
    height: 34px;
    border-radius: 5px;
    background-color: #6eb0e1;
    font-family: 'Roboto';
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    color: #ffffff;
    border: none;
}

.inputGroup{
    max-height : 30vh;
    overflow-y : scroll;
}

.successMsg {
    font-family: 'Roboto';
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: 0.15px;
    color: #4caf50;
}

.errorMsg {
    font-family: 'Roboto';
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: 0.15px;
    color: #f50018;
}

.deleteButton {
    height: 40px;
    border-radius: 5px;
    border: 2px solid #6eb0e1;
    font-family: 'Roboto';
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    color: #859dce;
}