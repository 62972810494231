.legendHead {
  width: 22px;
  height: 19px;
  margin: 1px 22px 1px 0;
  font-family: Roboto;
  font-size: 15px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.26;
  letter-spacing: 0.19px;
  color: #333;
}

.legendOval {
  width: 20px;
  height: 20px;
  border-radius: 20px;
}

.legendItem {
  max-width: 80px;
  height: 19px;
  margin-left: 5px;
  margin-right: 10px;
  font-family: Roboto;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.26;
  letter-spacing: 0.19px;
  color: #333;
}
